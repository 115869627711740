import moment from "moment";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as Yup from "yup";
import { successLogo, empty } from "./assets"
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  onClose?: any;
  getAppontment?: any;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  available_date: any;
  start_time: any;
  end_time: any;
  id: any;
  token: string;
  appointmentsList: any;
  isRefreshing: boolean;
  isAddAppointment: boolean;
  userData: any;
  initialValues: any;
  schema: any;
  availableDates: any;
  availableTimes: any;
  showMoreOptions: boolean,
  anchorEl: any;
  meetid: number;
  getMonthDate: Date | string;
  selectedTime: string,
  isReshedual: boolean,
  meeting_key: number,
  totalUser: number,
  perPage: number,
  page: number,
  alert: any,
  timeError: string,
  showDateTime: string,
  isCancled: boolean,
  appointementMoreOptions: string[];
  openAppointmentDetailsDialog: boolean;
  alertMessage: {
    isOpen: boolean,
    text: string,
  },
  openAccessRestrictedDialog: boolean;
  disableScheduleCall: boolean;
  isLoading: boolean;
  // Customizable Area End
}

export interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AppointmentmanagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  addAppointmentApiCallId: any;
  getAppointmentsListApiCallId: any;
  deleteAllAppointmentsApiCallId: any;
  appointmentDataApiCallId: any;
  availabletimeApiCallId: any;
  availabledateApiCallId: any;
  cancleshedualApiCallId: any;
  createShedualApiCallId: any;
  ReShedualApiCallId: any;
  account_id: number;
  emailId: string;
  account_type: string = "";
  organizationid: number = 0;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);
    let endTime = new Date();
    endTime.setMinutes(endTime.getMinutes() + 30);
    this.state = {
      // Customizable Area Start
      id: 0,
      start_time: new Date(),
      end_time: endTime,
      available_date: JSON.parse(localStorage.getItem('initialValues') as string)?.datevalue || null,
      appointmentsList: [],
      token: "",
      isRefreshing: false,
      isAddAppointment: false,
      userData: {},
      initialValues: {
        title: JSON.parse(localStorage.getItem('initialValues') as string)?.title || "",
        description: JSON.parse(localStorage.getItem('initialValues') as string)?.description || "",
        app: JSON.parse(localStorage.getItem('initialValues') as string)?.app || "",
        date: JSON.parse(localStorage.getItem('initialValues') as string)?.date || null,
        time: JSON.parse(localStorage.getItem('initialValues') as string)?.time || "",
        link: JSON.parse(localStorage.getItem('initialValues') as string)?.link || "",
      },
      schema: Yup.object().shape({
        title: Yup.string()
          .required("Enter meeting title"),
        description: Yup.string()
          .required("Enter description"),
        app: Yup.string()
          .required("Select meeting app"),
        time: Yup.string()
          .required("Select time"),
        date: Yup.date().typeError("Select date")
          .required("Select date"),
        link: Yup.string().when('app', {
          is: (app) => app === 'Other Custom Link',
          then: Yup.string().required('Please add link'),
          otherwise: Yup.string(),
        }),

      }),
      availableDates: [],
      availableTimes: [],
      showMoreOptions: false,
      anchorEl: null,
      meetid: JSON.parse(localStorage.getItem('meetid') as string)?.meetid || 0,
      getMonthDate: new Date(),
      selectedTime: JSON.parse(localStorage.getItem('initialValues') as string)?.time || "",
      isReshedual: JSON.parse(localStorage.getItem('initialValues') as string)?.isReshedual || false,
      meeting_key: JSON.parse(localStorage.getItem('meetid') as string)?.meeting_key || 0,
      totalUser: 0,
      perPage: 5,
      page: 1,
      alert: {},
      timeError: '',
      showDateTime: '',
      isCancled: false,
      appointementMoreOptions: [],
      openAppointmentDetailsDialog: false,
      alertMessage: {
        isOpen: false,
        text: "",
      },
      openAccessRestrictedDialog: false,
      disableScheduleCall: false,
      isLoading: false,
      // Customizable Area End
    };

    // Customizable Area Start
    this.account_id = JSON.parse(localStorage.getItem("userDetails") as string)?.meta?.id
    this.emailId = JSON.parse(localStorage.getItem("userDetails") as string)?.meta?.email
    this.account_type = JSON.parse(localStorage.getItem("userDetails") as string)?.meta?.user_account_type
    this.organizationid = JSON.parse(localStorage.getItem('organization') as string)?.id
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.getAppointments()
    this.getavailableDate()
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  receive = async (from: String, message: Message) => {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (apiRequestCallId) {
        case this.appointmentDataApiCallId:
          if (responseJson.errors?.[0]?.token) {
            this.logoutUser();
            this.saveTokenAlert(responseJson.errors[0].token);
            return;
          }
          if (responseJson.errors) {
            this.setState({ 
              openAccessRestrictedDialog: true,
              disableScheduleCall: true, 
            });
            return;
          }
          this.setState({
            userData: responseJson.profile_data,
            appointmentsList: responseJson.meetings_data,
            totalUser: responseJson.total_meeting_count
          });
          break;
        case this.availabletimeApiCallId:
          if (responseJson.errors?.[0]?.token) {
            this.logoutUser();
            this.saveTokenAlert(responseJson.errors[0].token);
            return;
          }
          this.setState({ availableTimes: responseJson.timeslots })
          break;
        case this.availabledateApiCallId:
          if (responseJson.errors?.[0]?.token) {
            this.logoutUser();
            this.saveTokenAlert(responseJson.errors[0].token);
            return;
          }

          this.setState({ availableDates: responseJson.dates }, () => {
            this.shouldDisableDate = this.createShouldDisableDateFunction();
          })
          break;
        case this.cancleshedualApiCallId:
          if (responseJson.errors?.[0]?.token) {
            this.logoutUser();
            this.saveTokenAlert(responseJson.errors[0].token);
            return;
          }

          this.setState({isCancled: true})
          if (responseJson.success) {
            this.getAppointments()
            this.setState({
              alert: {
                show: true,
                message: "You've cancelled the meeting successfully",
                status: 'Success!',
                image: successLogo,
                bottomMessage: 'To schedule more calls, please click'
              }
            })
          } else {
            this.setState({
              alert: {
                show: false,
                message: responseJson.data,
                status: 'failed',
                image: empty,
                bottomMessage: 'try again. Click'
              }
            })
          }
          break;
        case this.createShedualApiCallId:
          this.setState({ isLoading: false });
          if (responseJson.errors?.[0]?.token) {
            this.logoutUser();
            this.saveTokenAlert(responseJson.errors[0].token);
            return;
          }

          if (responseJson.data) {
            this.setState({
              ...this.state,
              alertMessage: {
                isOpen: true,
                text: "Meeting booked successfully",
              }
            });
          }
          this.props.getAppontment()
          break;
        case this.ReShedualApiCallId:
          this.setState({ isLoading: false });
          if (responseJson.errors?.[0]?.token) {
            this.logoutUser();
            this.saveTokenAlert(responseJson.errors[0].token);
            return;
          }

          if (responseJson.data) {
            this.setState({
              ...this.state,
              alertMessage: {
                isOpen: true,
                text: "Meeting rescheduled successfully",
              }
            });
          }
          localStorage.removeItem("initialValues")
          this.props.getAppontment()
          break;
      }
    }
    // Customizable Area End
  };

  // Customizable Area Start
  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  addAppointment(): boolean {
    if (
      this.isStringNullOrBlank(this.state.available_date) ||
      this.isStringNullOrBlank(this.state.start_time) ||
      this.isStringNullOrBlank(this.state.end_time)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory,
        ""
      );
      return false;
    }

    const header = {
      "Content-Type": configJSON.appointmentApiContentType,
      token: this.state.token,
    };

    const attrs = {
      start_time: moment(this.state.start_time).format("HH:mm A"),
      end_time: moment(this.state.end_time).format("HH:mm A"),
      availability_date: moment(this.state.available_date, "DD/MM/YY").format(
        "YY/MM/DD"
      ),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addAppointmentApiCallId = requestMessage.messageId;

    const httpBody = {
      ...attrs,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.appointmentAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addAppointmentAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getAppointmentList = (token: any) => {
    const header = {
      "Content-Type": configJSON.appointmentApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const attrs = {
      service_provider_id: "1",
      availability_date: this.state.available_date,
    };

    this.getAppointmentsListApiCallId = requestMessage.messageId;
    let urlParams = new URLSearchParams(attrs).toString();

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.appointmentAPiEndPoint}?${urlParams}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAppointmentListAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  navigateToAppointments = () => {
    this.props.navigation.navigate("Appointments");
  };
  // Customizable Area Start

  handleClose = () => {
    this.setState({ isAddAppointment: false, isReshedual: false })
  }

  handleCloseCancle = () => {
    this.setState({ isCancled: false })
  }

  handleConfirmTime = () => {
    const initialValues = {
      title: JSON.parse(localStorage.getItem('initialValues') as string)?.title || "",
      description: JSON.parse(localStorage.getItem('initialValues') as string)?.description || "",
      app: JSON.parse(localStorage.getItem('initialValues') as string)?.app || "",
      date: JSON.parse(localStorage.getItem('initialValues') as string)?.date || null,
      time: this.state.selectedTime,
      link: JSON.parse(localStorage.getItem('initialValues') as string)?.link || "",
    }
    localStorage.setItem("initialValues", JSON.stringify(initialValues))
    this.setState({ initialValues: { ...this.state.initialValues, time: this.state.selectedTime } })
    if (this.state.selectedTime) {
      this.handlesetValue()
      this.setState({ showDateTime: "" })
    } else {
      this.setState({ timeError: 'please select time' })
    }
  }

  handlOpen = () => {
    localStorage.removeItem("initialValues")
    this.setState({ isAddAppointment: true })
  }

  getAppointments = async () => {
    const header = {
      "Content-Type": configJSON.appointmentApiContentType,
      "token": await getStorageData("authToken")
    };

    let httpBody = {
      "account_id": this.account_id,
      "page_no": this.state.page,
      "items_per_page": this.state.perPage
    } as { company_id?: number };

    if(this.account_type === "portfolio_manager"){
      httpBody.company_id = this.organizationid
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.appointmentDataApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.ApponntmentsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addAppointmentAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleReshedualMeeting = async (value: any) => {

    const time = value?.time.split("to")[0].trim();
    const header = {
      "Content-Type": configJSON.appointmentApiContentType,
      "token": await getStorageData("authToken")
    };

    const appValue = {
      'Zoho Meeting app': 'zoho',
      'Other Custom Link': "other"
    } as any

    const httpBody = {
      "data": {
        "session": {
          "meeting_type": appValue[value?.app],
          "meeting_link": value?.link,
          "meeting_key": this.state.meeting_key !== 0 ? this.state.meeting_key : this.state.meetid,
          "topic": value?.title,
          "agenda": value?.description,
          "startTime": `${moment(value?.date).format('ll')} ${time}`,
          "presenter": 809845412,
          "service_provider_id": this.state.userData.id,
          "duration": 3600000,
          "timezone": "Asia/Calcutta",
          "participants": [
            {
              "email": this.emailId
            }
          ]
        }
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.ReShedualApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.ReShedualAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addAppointmentAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  handleCreateShedual = async (value: any) => {
    const time = value?.time.split("to")[0].trim();
    const header = {
      "Content-Type": configJSON.appointmentApiContentType,
      "token": await getStorageData("authToken")
    };

    const appValue = {
      'Zoho Meeting app': 'zoho',
      'Other Custom Link': "other"
    } as any

    const httpBody = {
      "data": {
        "session": {
          "meeting_type": appValue[value?.app],
          "meeting_link": value?.link,
          "topic": value?.title,
          "agenda": value?.description,
          "startTime": `${moment(value?.date).format('ll')} ${time}`,
          "presenter": 809845412,
          "service_provider_id": this.state.userData.id,
          "duration": 3600000,
          "timezone": "Asia/Calcutta",
          "participants": [
            {
              "email": this.emailId
            }
          ]
        }
      }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createShedualApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createShedualAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addAppointmentAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleSubmit = (value: any) => {
    this.setState({ isLoading: true });
    if (this.state.isReshedual) {
      this.handleReshedualMeeting(value)
    } else {
      this.handleCreateShedual(value)
    }
  }

  subtractOneDay = (dateString: Date) => {
    if (dateString) {
      const currentDate = new Date(dateString);
      currentDate.setDate(currentDate.getDate() - 1);
      return currentDate.toISOString().split('T')[0];
    }
  };

  createShouldDisableDateFunction = () => {
    return (date: Date) => {
      const modifiedDatesArray = this.state.availableDates;
      const formattedDate = date.toISOString().split('T')[0];
      return !modifiedDatesArray?.includes(formattedDate);
    };
  };


  shouldDisableDate = (date: Date) => {
    const modifiedDatesArray = this.state.availableDates;
    const formattedDate = date.toISOString().split('T')[0];
    const isDisabled = !modifiedDatesArray?.includes(formattedDate);
    return isDisabled;
  };

  onMonthChange = (date: Date) => {
    this.setState({ getMonthDate: date }, () => {
      this.getavailableDate()
    })
  }

  handleReshedual = () => {
    this.setState({ isReshedual: true, isAddAppointment: true })
    this.getAppointments()
    this.getavailableDate()
    const { appointmentsList, meetid } = this.state
    const formData = appointmentsList.find((item: any) => item.id === meetid)
    const appValue = {
      'zoho': 'Zoho Meeting app',
      "other": 'Other Custom Link'
    } as any

    const datevalue = this.subtractOneDay(formData?.meeting_date)
    this.setState({ available_date: datevalue })

    const initialValues = {
      title: formData.title,
      description: formData.description,
      app: appValue[formData.meeting_type],
      date: new Date(formData?.meeting_date),
      time: `${formData.start_time} to ${formData.end_time}`,
      link: formData.meeting_type === "other" ? formData.join_link : "",
      datevalue: datevalue,
      isReshedual: true
    }
    localStorage.setItem("initialValues", JSON.stringify(initialValues))
  }
  updatePagination = () => {
    const { page, perPage, totalUser } = this.state;
    const pagesLength = totalUser - 1
    const totalPages = Math.ceil(pagesLength / perPage);
    if (page > totalPages) {
      this.setState({ page: totalPages });
    }
  };
  setDate = (date: Date) => {
    const currentDate = new Date(date);
    currentDate.setDate(currentDate.getDate());
    const datevalue = currentDate.toISOString().split('T')[0];
    this.setState({ available_date: datevalue })
    const initialValues = {
      title: JSON.parse(localStorage.getItem('initialValues') as string)?.title || "",
      description: JSON.parse(localStorage.getItem('initialValues') as string)?.description || "",
      app: JSON.parse(localStorage.getItem('initialValues') as string)?.app || "",
      date: date,
      time: JSON.parse(localStorage.getItem('initialValues') as string)?.time || "",
      link: JSON.parse(localStorage.getItem('initialValues') as string)?.link || "",
      datevalue: datevalue,
    }
    localStorage.setItem("initialValues", JSON.stringify(initialValues))
    this.setState({ available_date: date, timeError: '' })
    this.setState({ initialValues: { ...this.state.initialValues, date } })
    this.getavailableTime(date)
  }

  async getavailableTime(date: Date | null) {
    const header = {
      "Content-Type": configJSON.appointmentApiContentType,
      "token": await getStorageData("authToken")
    };

    const httpBody = {
      "account_id": this.account_id,
      "date": moment(date).format("YYYY-MM-DD")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.availabletimeApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.availabletimeAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addAppointmentAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  async getavailableDate() {
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const d = new Date(this.state.getMonthDate);
    let month = months[d.getMonth()];
    const header = {
      "Content-Type": configJSON.appointmentApiContentType,
      "token": await getStorageData("authToken")
    };

    const httpBody = {
      "account_id": this.account_id,
      "month": month,
      "year": d.getFullYear()
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.availabledateApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.availableDateAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addAppointmentAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Genereate more options for the kebab menu in expert schedule page
  generateAppointementMoreOptions = (appointementStatus: string) => {
    let appointementMoreOptions: string[];
    switch (appointementStatus) {
      case "Scheduled":
        appointementMoreOptions = ["Cancel", "Reschedule", "View"];
        break;
      case "Completed ":
        appointementMoreOptions = ["View"];
        break;
      case "Cancelled":
        appointementMoreOptions = [];
        break;
      default:
        appointementMoreOptions = [];
        break;
    }
    this.setState({ 
      appointementMoreOptions,
      showMoreOptions: !this.state.showMoreOptions,
    });
  }

  toggleMoreOptions = (
    event: React.MouseEvent<any>, meetkey: string, id: number, meetStatus: string
  ) => {
    this.generateAppointementMoreOptions(meetStatus);

    const allid = {
      meetid: id,
      meeting_key: Number(meetkey)
    }
    localStorage.setItem("meetid", JSON.stringify(allid))
    this.setState({ meetid: id, meeting_key: Number(meetkey) })
    this.setState({ showMoreOptions: !this.state.showMoreOptions });
    if (!this.state.showMoreOptions) {
      this.setState({ anchorEl: event.currentTarget });
    } else {
      this.setState({ anchorEl: null });
    }
  };

  handleKababOptions = (value: string) => {
    switch (value) {
      case "Cancel":
        this.handleCancelShedual();
        break;
      case "Reschedule":
        this.handleReshedual();
        break;
      case "View":
        this.handleOpenAppointmentDetailsDialog(true);
    }
    this.setState({ showMoreOptions: false });
  };

  handleChangeTime = (value: any) => {
    if (this.state.available_date) {
      this.getavailableTime(this.state.initialValues.date)
      this.setState({ showDateTime: 'time' })
    } else {
      this.setState({ timeError: 'please select date first' })
    }
    if(!this.state.isReshedual){
      const initialValues = {
        title: value.title,
        description: value.description,
        app: value.app,
        date: value.date,
        time: this.state.selectedTime,
        link: value.link,
        datevalue: value.date,
      }
      localStorage.setItem("initialValues", JSON.stringify(initialValues))
    }
  }

  openCalender = (value: any) => {
    this.setState({ timeError: '' })
    this.setState({ available_date: moment(value.date).format("YYYY-MM-DD") })
    const initialValues = {
      title: value.title,
      description: value.description,
      app: value.app,
      date: value.date,
      time: "",
      link: value.link,
      datevalue: moment(value.date).format("YYYY-MM-DD")
    }
    localStorage.setItem("initialValues", JSON.stringify(initialValues))
    this.setState({ showDateTime: 'date' })
  }

  selectTime = (value: string) => {
    this.setState({ selectedTime: value, timeError: '' })
  }

  handlebackTime = () => {
    this.handlesetValue()
    this.setState({ selectedTime: JSON.parse(localStorage.getItem('initialValues') as string)?.time || "", timeError: '' })
    this.setState({ showDateTime: '' })
  }

  handlesetValue = () => {
    this.setState({
      initialValues: {
        title: JSON.parse(localStorage.getItem('initialValues') as string)?.title || "",
        description: JSON.parse(localStorage.getItem('initialValues') as string)?.description || "",
        app: JSON.parse(localStorage.getItem('initialValues') as string)?.app || "",
        date: JSON.parse(localStorage.getItem('initialValues') as string)?.date || null,
        time: JSON.parse(localStorage.getItem('initialValues') as string)?.time || "",
        link: JSON.parse(localStorage.getItem('initialValues') as string)?.link || "",
      },
    })
  }

  handleConfirmDate = () => {
    if (this.state.available_date) {
      this.handlesetValue()
      this.setState({ showDateTime: "time" })
    } else {
      this.setState({ timeError: 'please select date' })
    }
  }

  handleCancelShedual = async () => {
    const header = {
      "Content-Type": configJSON.appointmentApiContentType,
      "token": await getStorageData("authToken")
    };

    const httpBody = {
      "meeting_type": this.state.meeting_key !== 0 ? "zoho" : "other",
      "meeting_key": this.state.meeting_key !== 0 ? this.state.meeting_key : this.state.meetid,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.cancleshedualApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.cancleShedualAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addAppointmentAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.updatePagination()
  }

  handleTablePageChange = (e: any, page: number) => {
    this.setState({ page }, () => {
      this.getAppointments()
    })
  }

  handleTableNumberChange = (e: any,) => {
    this.setState({ perPage: e.target.value, page: 1 }, () => {
      this.getAppointments()
    })
  }

  setShowSubmitAlert = () => {
    this.setState({
      alert: {
        show: false,
        message: "",
        status: ''
      }
    })
  }

  checkDateandTime = (meetingDate: string, meetingTime: string) => {
    const currentDate = new Date();
    const meetingDateTime = new Date(`${meetingDate} ${meetingTime}`);

    const timeDiffInMinutes = Math.floor((meetingDateTime.getTime() - currentDate.getTime()) / 60000);

    // Enable the button if the current time is within 10 minutes of the meeting start or after the meeting start time
    return timeDiffInMinutes <= 10 || currentDate >= meetingDateTime;
  }

  handleErrors(errors: any, touched: any, feild: any) {
    return errors[feild] && touched[feild] ? errors[feild] : ''
  }

  isReshedual = () => {
    return this.state.isReshedual ? "Reschedule" : "Create Schedule"
  }

  isGoNext(errors: any, values: any) {
    const isError = Object.keys(errors || {})?.length === 0
    let isValue;
    if (values.app === "Other Custom Link") {
      isValue = Object.values(values)?.every((item: any) => item?.length !== 0)
    } else {
      delete values.link
      isValue = Object.values(values)?.every((item: any) => item?.length !== 0)
    }
    return isError && isValue ? "#43D270" : "#B1B3C3"
  }

  // Handle open close of the view appointment dialog
  handleOpenAppointmentDetailsDialog = (status: boolean) => {
    this.setState({ openAppointmentDetailsDialog: status });
  }

  // Handle open close of the access restricted dialog
  handleOpenAccessRestrictedDialog = (status: boolean) => {
    this.setState({ openAccessRestrictedDialog: status });
  }

  handleSnackbarClose = () => {
    this.setState({
      ...this.state,
      alertMessage: {
        ...this.state.alertMessage,
        isOpen: false,
      }
    });
    this.props.onClose();
  }

  navigateToGetStartedPage = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));

    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "GettingStarted"
    );

    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(msg);
  }

  logoutUser = () => {
    localStorage.clear();
    window.open("/login/primary", "_self");
  }

  saveTokenAlert = (message: string) => {
    const alert = {
      message,
      isOpen: true,
    }

    setStorageData("tokenAlert", JSON.stringify(alert));
  }


  // Customizable Area End
}
